import React from "react";
import "./Store.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Vectorstore from "../../images/Vectorstore.png";
import rosemary from "../../images/rosemary.png";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import redblur from "../../images/redblur.png";
import { Datas } from "../../Assets/Datas";
import Nungambakam from '../../images/Nungambakam.jpg';
import Kodambakkam from '../../images/Kodambakkam.jpg';
import Shaliabnda from '../../images/Shalibanda.jpg';
import Abids from '../../images/Abids.jpg';
import { Helmet } from "react-helmet";

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <MdNavigateNext className="Store-arrows" />
    </div>
  );
};
const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <MdNavigateBefore className="Store-arrows" />
    </div>
  );
};
const Card = ({ item }) => {
  return (

    <div className="Store-card">
      <img
        src={item}
        alt=""
        style={{
          width: "100%",
          height: "33vh",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
      />
      <p className="Store-card-header">Store Name</p>
      <p style={{ fontWeight: "bold", fontSize: "16px" }}>Location</p>
      <p
        style={{
          fontWeight: "400",
          fontSize: "15px",
          color: "grey",
        }}
      >
        Desciption
      </p>
      <div style={{ textAlign: "center", color: "turquoise" }}>
        <BsFacebook className="Store-icons" />
        <BsInstagram className="Store-icons" />
        <BsTwitter className="Store-icons" />
      </div>
    </div>

  );
};



const Store = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,

  };
  return (
    <div className="Stores">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="Store-header">
              <span style={{ color: "#2cbbbf" }}>Our</span>
              <span style={{ color: "#e32227" }}> Restaurant</span> Stores
            </h2>
            <p className="Store-desc">
              Every Hapningz outlet is defined by its specified value.
              With outlets strategically placed at prime locations, we ensure that our customers enjoy our delicious food and exceptional service.

            </p>
            <div className="Store-section">
              {/* <Slider
                autoplay
                autoplaySpeed={3000}
                initialSlide={1}
                infinite
                slidesToShow={2}
                slidesToScroll={1}
                rtl={true}
                prevArrow={<PreviousBtn />}
                nextArrow={<NextBtn />}
              >
                {Datas.map((item) => (
                  <Card item={item} />
                ))}
              </Slider> */}
              <Slider {...settings}>
                <div>
              
                  <div className="Store-card">
                    <img
                      src={Nungambakam}
                      alt="best fast food restaurants in Nungambakam"
                      style={{
                        width: "100%",
                        height: "33vh",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    />
                    <p className="Store-card-header">Nungambakam</p>
                    <p style={{ fontWeight: "bold", fontSize: "16px" }}>Location</p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "grey",
                      }}
                    >
                      Desciption
                    </p>
                    <div style={{ textAlign: "center", color: "turquoise" }}>
                      <BsFacebook className="Store-icons" />
                      <BsInstagram className="Store-icons" />
                      <BsTwitter className="Store-icons" />
                    </div>
                  </div>

                </div>

                <div>
                  <div className="Store-card">
                    <img
                      src={Kodambakkam}
                      alt="franchies food in Kodambakkam"
                      style={{
                        width: "100%",
                        height: "33vh",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    />
                    <p className="Store-card-header">Kodambakkam</p>
                    <p style={{ fontWeight: "bold", fontSize: "16px" }}>Location</p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "grey",
                      }}
                    >
                      Desciption
                    </p>
                    <div style={{ textAlign: "center", color: "turquoise" }}>
                      <BsFacebook className="Store-icons" />
                      <BsInstagram className="Store-icons" />
                      <BsTwitter className="Store-icons" />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="Store-card">
                    <img
                      src={Shaliabnda}
                      alt="Best Fast Food Restaurants in Shaliabnda"
                      style={{
                        width: "100%",
                        height: "33vh",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    />
                    <p className="Store-card-header">Shaliabnda</p>
                    <p style={{ fontWeight: "bold", fontSize: "16px" }}>Location</p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "grey",
                      }}
                    >
                      Desciption
                    </p>
                    <div style={{ textAlign: "center", color: "turquoise" }}>
                      <BsFacebook className="Store-icons" />
                      <BsInstagram className="Store-icons" />
                      <BsTwitter className="Store-icons" />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="Store-card">
                    <img
                      src={Abids}
                      alt="Best Restaurants in Abids"
                      style={{
                        width: "100%",
                        height: "33vh",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    />
                    <p className="Store-card-header">Abids</p>
                    <p style={{ fontWeight: "bold", fontSize: "16px" }}>Location</p>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "grey",
                      }}
                    >
                      Desciption
                    </p>
                    <div style={{ textAlign: "center", color: "turquoise" }}>
                      <BsFacebook className="Store-icons" />
                      <BsInstagram className="Store-icons" />
                      <BsTwitter className="Store-icons" />
                    </div>
                  </div>
                </div>

              </Slider>

            </div>
            <img src={redblur} className="Store-blur" alt="blur vector" />
          </div>

          <div className="col-lg-6">
            <img src={Vectorstore} className="Store-image" alt="Store" />
            <img src={rosemary} className="Store-plant" alt="Green leaf" />
          </div>
        </div>
      </div>
      <div className="Store-bar"></div>
    </div>
  );
};

export default Store;
