import React from "react";
// import Navbar from "../Navbar/Navbar";
import Navs from "../Navs/Navs";
import Footer from "../../components/Footer/Footer";
import "./MyStores.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { dataDigital } from "./Data.js";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";



function MyStores() {
  var storesettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="title" content="Hapningz - Best Fast Food Restaurants" />
        <meta name="description"
          content="  Whether you're looking for a quick lunch break or a casual dinner
          with friends and family, we have the perfect outlet for you. Our
          outlets are equipped with state-of-the-art kitchen equipment and
          modern cooking tools, our menu is diverse, and our staffs are
          dedicated to providing excellent service, with a focus on quality
          and customer satisfaction. With outlets strategically placed at
          prime locations, we ensure that our customers enjoy our delicious
          food and exceptional service. Our outlets have their unique
          ambiance and atmosphere, and are designed to provide a comfortable
          and convenient dining experience. Best Fast Food Restaurants in Kodambakkam" />
        <title>Hapningz - Best Fast Food Restaurants</title>
        <link rel="canonical" href="https://hapningz.com/MyStores" />
      </Helmet>
      <Navs />
      <div className="MyStores">
        <div className="container">
          <div className="MyStores-sec">
            <h1 className="MyStores-head">Our Outlets</h1>
            <p className="MyStores-desc">
              Whether you're looking for a quick lunch break or a casual dinner
              with friends and family, we have the perfect outlet for you. Our
              outlets are equipped with state-of-the-art kitchen equipment and
              modern cooking tools, our menu is diverse, and our staffs are
              dedicated to providing excellent service, with a focus on quality
              and customer satisfaction. With outlets strategically placed at
              prime locations, we ensure that our customers enjoy our delicious
              food and exceptional service. Our outlets have their unique
              ambiance and atmosphere, and are designed to provide a comfortable
              and convenient dining experience.
            </p>
          </div>
          <Slider {...storesettings} >
            {dataDigital.map((item) => (
             
                <div className="MyStore-card">  
                  <div className="MyStore-cardtop">
                    <Link to={item.linkUrl}> 
                    <img src={item.linkImg} className="MyStore-cardimage" alt={item.title} /></Link>
                    <h4 style={{ marginTop: '20px', fontSize: '22px' }}>{item.title}</h4>
                  </div>
                  {/* <div className="MyStore-cardbottom">
                    <h6>{item.price}</h6>
                    <p>{item.category}</p>
                  </div> */}
              

                </div>
              
             
            ))}
          </Slider>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default MyStores;
