import React from 'react';
import './Banner.css';
import Banners from "../../images/banners.png";
import Bansmall from "../../images/Bansmall.png";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className='Banner-full'>

      <div className='d-flex justify-content-center'>
        <img src={Banners} className="banner-img" alt="bannershot" />
        <img src ={Bansmall} className="Banner-small" alt="mobile-banner"/>
        <p className='Banner-desc-head'>Food Banner of the Menu</p>
        <Link to ="/">
          <button className='banner-btn' onClick="/">View</button>
          </Link>
      </div>
    </div>
  )
}

export default Banner;