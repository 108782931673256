import React from 'react';
import "./Contact.css";
// import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Navs from '../Navs/Navs';
import { Helmet } from "react-helmet";

const Contact = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content="Hapningz - Contact Us" />
                <meta name="description"
                    content="Welcome to Hapningz, your go-to quick-service restaurant (QSR) brand. We value your feedback and are dedicated to providing you with the best possible customer service. If you have any questions, comments, or concerns, please don't hesitate to reach out to us." />
                <title>Hapningz - Contact Us</title>
                <link rel="canonical" href="https://hapningz.com/Aboutus" />
            </Helmet>
            <Navs />
            <div className='Contact'>
                <div className='container'>
                    <div className='Contact-sec'>
                        <h1>Contact Us</h1>
                        <p>Welcome to Hapningz, your go-to quick-service restaurant (QSR) brand. We value your feedback and are dedicated to providing you with the best possible customer service. If you have any questions, comments, or concerns, please don't hesitate to reach out to us.</p>

                        <h5>Here are a few ways you can contact us:</h5>
                        <p>Phone: You can reach us by phone at ________. Our customer service team is available Monday through Friday, from 9 am to 5 pm.</p>

                        <p>Email: You can also contact us by email at info@hapningz.com. Our customer service team will respond to your email as soon as possible.</p>

                        <p>Social Media: You can also reach out to us on our social media channels, such as Facebook, Instagram, and Twitter. We will respond to your message as soon as we can.</p>

                        <p>Visit us: If you have any concerns or questions, you can always visit one of our locations, and we will be happy to help you.</p>

                        <p>We appreciate your feedback and are committed to providing you with the best possible service. Thank you for choosing Hapningz for your quick-service dining needs. We look forward to hearing from you!</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact;