import React from "react";
import "./MyFranchise.css";
import Bana from "../../images/Bana.png";
// import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Navs from "../Navs/Navs";
import { Helmet } from "react-helmet";


const MyFranchise = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content="Hapningz - Franchise" />
                <meta name="description"
                    content="Hapningz offers a unique and cost-effective franchise business
                    model for quick serve restaurant, with a proven track record of
                    success. Our brand familiarity, sizeable fan base, and exceptional
                    support and training make it an attractive option for those
                    looking to invest in the food industry. We look forward to working
                    with you in establishing a thriving QSR outlet in your city." />
                <title>Hapningz - Franchise</title>
                <link rel="canonical" href="https://hapningz.com/MyFranchise" />
            </Helmet>
            <Navs />
            <div className="MyFranchise">
                <div className="container">
                    <div className="MyFranchise-sec">
                        <h1>Franchise</h1>
                        <p>
                            Hapningz offers a unique and cost-effective franchise business
                            model for quick serve restaurant, with a proven track record of
                            success. Our brand familiarity, sizeable fan base, and exceptional
                            support and training make it an attractive option for those
                            looking to invest in the food industry. We look forward to working
                            with you in establishing a thriving QSR outlet in your city.
                        </p>

                        <h4>Why Hapningz Franchisee?</h4>
                        <p>
                            If you possess a desire to provide exceptional food service,
                            possess entrepreneurial spirit, strive for growth and success, and
                            are willing to embrace new challenges, then becoming a Hapningz
                            franchisee is an excellent opportunity for you. Hapningz has
                            quickly established a reputation as a premier fast food QSR
                            destination in South India, as attested by our loyal customer
                            base. Our Quick Service Restaurants are unparalleled in terms of
                            both value and the taste of our dishes. We invite you to join us
                            in our mission to provide exceptional food service to our valued
                            customers.
                        </p>
                        <p>
                            Get connected with Hapningz & be a part of India’s fastest growing
                            Quick Service Restaurant food chain industry.
                        </p>
                        <h4>USPs of Hapningz </h4>
                        <div className="MyFranchise-usp">
                            <div className="MyFranchise-usp1">
                                <li>Proven Brand Loyalty</li>
                                <li>Rapid Growth</li>
                                <li>Tech-Driven</li>
                                <li>Economical</li>
                                <li>Self-Tested Menu</li>
                            </div>
                            <div className="MyFranchise-usp2">
                                <li>Transparency</li>
                                <li>Hand-Holding Support</li>
                                <li>Expert Guidance</li>
                                <li>Corporate Culture</li>
                                <li>International Standards</li>
                            </div>
                        </div>

                        <h6>
                            Hapningz is a rapidly growing brand that boasts a number of unique
                            selling points that set us apart from our competitors.{" "}
                        </h6>
                        <p>
                            In summary, Hapningz is a reputable and trusted brand that is
                            known for its established brand loyalty, rapid growth, tech-driven
                            approach, cost-effective solutions, self-tested menu,
                            transparency, supportive customer service, professional corporate
                            culture, and compliance with international standards.
                        </p>

                        <h4>What can Hapningz Franchisee offer you?</h4>
                        <p>
                            Apart from the trendiest QSR Franchise opportunity, Hapningz
                            ensures your development. Our experience in the food industry,
                            clubbed with the techy expertise of the neo-internet age of Web
                            and Social Media provides an excellent opportunity for a
                            profitable business.
                        </p>

                        <p>
                            Besides the techy advantage, our success formula for QSR franchise
                            business includes our adaptability to developing and testing new
                            processes, our time-tested menu, outstanding branding, qualified
                            team of hospitality specialities, and the knowledge to build a
                            vibrant, happening atmosphere in every outlet.
                        </p>
                        <p>
                            Hapningz offers a unique and cost-effective franchise business
                            model for quick serve restaurant, with a proven track record of
                            success. Our brand familiarity, sizeable fan base, and exceptional
                            support and training make it an attractive option for those
                            looking to invest in the food industry. We look forward to working
                            with you in establishing a thriving QSR outlet in your city.
                        </p>
                        <div className="MyFranchise-models">
                            <h4>Business Models</h4>
                            <p>Master Franchise</p>
                            <p>FOFO-Franchise Owned Franchise Operated</p>
                            <p>FOCO-Franchise Owned Company Operated</p>
                            <p>FOPO-Franchise Owned Partner Operated</p>
                        </div>
                        <h3>Our Restauant Models</h3>
                        <div className="MyFranchise-pick">

                            <div className="MyFranchise-pick1">
                                <img src={Bana} className="MyFranchise-pick-img" alt="smile" />
                            </div>
                            <div className="MyFranchise-pick2">
                                <button className="MyFranchise-pick2-btn1" onClick={"/"}>Pick n Go</button>
                                <button className="MyFranchise-pick2-btn2" onClick={"/"}>Pick n Eat</button>
                                <button className="MyFranchise-pick2-btn3" onClick={"/"}>Fine Dine</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default MyFranchise;
