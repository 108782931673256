import React from "react";
import "./intro.css";
import heroimage2 from "../../images/heroimage2.png";
import heroimage3 from "../../images/heroimage3.png";
import { AiOutlineSearch } from "react-icons/ai";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import orange from "../../images/orange.png";
import uparrow from "../../images/uparrow.png";
import Wave from "../../images/Wave.png";
import heart from "../../images/heart.png";
import arrowdown from "../../images/arrowdown.png";
import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";
// import InstagramEmbed from 'react-instagram-embed';
import Video1 from '../../images/video/video1.mp4';
import Video2 from '../../images/video/video2.mp4';
import Video3 from '../../images/video/video3.mp4';
import Video4 from '../../images/video/video4.mp4';
import Video5 from '../../images/video/video5.mp4';
import Video6 from '../../images/video/grilledwings.mp4';


const Intro = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    cssEase: "linear"
  };
  return (
    <div className="Landing">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="front-content-1">
              <p className="people">
                {" "}
                <img src={heart} className="people-heart" alt="people heart" />
                People Trust Us
              </p>
              <Carousel controls={false} indicators={false}>
                <Carousel.Item interval={3000}>
                  <h1 className="front-heading">

                    <span className="front-heading-1">Discover  </span> Our Delicious{" "}
                    <span className="front-heading-2"> Menu</span> {" "}
                  </h1>

                  <p className="front-text">
                    Enjoy a wide variety of delicacies, from burgers and fries
                    to salads and sandwiches, there's something for everyone,
                    from a quick bite to a full-course meal.
                  </p>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <h2 className="front-heading">
                    Enjoy our <span className="front-heading-1"> Fast </span>{" "}
                    and <span className="front-heading-2">Friendly</span>{" "}
                    Service{" "}
                  </h2>

                  <p className="front-text">
                    Time is precious, whether it’s yours or ours. Come and enjoy
                    your meal without having to wait, thanks to our fast and
                    friendly service.
                  </p>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <h2 className="front-heading">
                    Join us for{" "}
                    <span className="front-heading-1"> Breakfast, </span>
                    <span className="front-heading-2">Lunch</span> or
                    <span className="front-heading-1"> Dinner</span>
                  </h2>

                  <p className="front-text">
                    Whether you're looking for a quick breakfast, a delicious
                    lunch, or a satisfying dinner, Hapningz has got you covered.
                    We're open early in the morning and late into the evening.
                    {/* so you can stop by whenever you're hungry. */}
                  </p>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <h2 className="front-heading">
                    We<span className="front-heading-1"> Serve</span> at your{" "}
                    <span className="front-heading-2">Parties</span> as well{" "}
                  </h2>

                  <p className="front-text">
                    Worried about serving delicacies for events? Sit back and
                    relax,we will take care of relishing your guests for any
                    event at place.Birthdays, gettogether or
                    business events etc.
                  </p>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <h2 className="front-heading">
                    <span className="front-heading-1"> Gift Cards</span> are{" "}
                    <span className="front-heading-2">Available</span>{" "}
                  </h2>

                  <p className="front-text">
                    Looking for the perfect gift for a foodie in your life? Look
                    no further than Hapningz gift cards! Grab one for the
                    perfect gift for any occasion.
                  </p>
                </Carousel.Item>
              </Carousel>
              <img src={orange} className="front-orange" alt="people orange" />

            </div>
            <div className="front-buttons">
              <button className="front-buttons-line-1" onClick="/">
                Store Locator
              </button>
              <button className="front-buttons-line-2" onClick="/">
                Offers
              </button>
              <button className="front-buttons-line-3" onClick="/">
                Menu
              </button>
            </div>
            <div>
              <input
                type="text"
                placeholder="Search Stores"
                className="front-input"
              />
              <AiOutlineSearch className="front-search" />
            </div>
            <div className="front-video">
              <button className="front-play">
                <PlayArrowIcon />
              </button>
              <p className="front-video-text">Watch Video</p>
            </div>
            <div className="front-arrow-down">
              <img
                src={arrowdown}
                className="front-down"
                onClick="/"
                loading="lazy"
                alt="scrollarrow"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <Slider {...settings}>
              <div>
                <div className="slider">
                  <img src={heroimage2} className="slider-hero" alt="fried chicken in chennai" />
                  <img
                    src={uparrow}
                    className="slider-uparrow"
                    loading="lazy"
                    alt="uparrow"
                  />
                  <img src={Wave} className="slider-waves" alt="sandwiches in chennai" />
                </div>
              </div>
              <div>
                <div className="slider">
                  <img src={heroimage3} className="slider-hero" alt="fast food in chennai" />

                  <img src={Wave} className="slider-waves" alt="fast food in chennai" />
                </div>
              </div>
            </Slider>

          </div>
        </div>
        <div className={'reals'}>

          <div className={"row"}>
            <div className={"col-lg-2 col-md-6 col-6"}>
              <video width="100%" height="auto" controls muted loop autoPlay   >
                <source src={Video1} type="video/mp4" />
              </video>

            </div>
            <div className={"col-lg-2 col-md-6 col-6"}>

              <video width="100%" height="auto" controls muted loop autoPlay   >
                <source src={Video2} type="video/mp4" />
              </video>
            </div>
            <div className={"col-lg-2 col-md-6 col-6"}>

              <video width="100%" height="auto" controls muted loop autoPlay   >
                <source src={Video3} type="video/mp4" />
              </video>
            </div>
            <div className={"col-lg-2 col-md-6 col-6"}>

              <video width="100%" height="auto" controls muted loop autoPlay   >
                <source src={Video4} type="video/mp4" />
              </video>
            </div>
            <div className={"col-lg-2 col-md-6 col-6"}>

              <video width="100%" height="auto" controls muted loop autoPlay   >
                <source src={Video5} type="video/mp4" />
              </video>
            </div>
            <div className={"col-lg-2 col-md-6 col-6"}>

              <video width="100%" height="auto" controls muted loop autoPlay   >
                <source src={Video6} type="video/mp4" />
              </video>
            </div>
          </div>

         
        </div>
      </div>


    </div>
  );
};

export default Intro;
