import React from "react";
import "./Footer.css";
import happb from "../../images/happb.png";
import { ImFacebook } from "react-icons/im";
import { BsFillTelephoneFill, BsInstagram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsPinterest } from "react-icons/bs";
import { IoMdMail } from "react-icons/io";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-fluid">
          <div className="row" style={{ padding: "25px 80px" }}>
            <div className="col-lg-3 col-md-6 logo-about">
              <img src={happb} className="happ-logo" alt="happlogo" />
              <p className="footer-links" onClick="/">
                Indulge in a quick delicious bite at Hapningz. Serving smiles and treating your taste buds ensures a satisfying experience every visit.
              </p>
              <p> ⚠️ Warning: Every visit may to lead to increase in cravings.</p>
              {/* <div className="d-flex align-items-center">
                <ImFacebook className="footer-icons" onClick />
                <BsInstagram className="footer-icons" />
                <BsTwitter className="footer-icons" />
              </div> */}
            </div>
            <div className="col-lg-3 col-md-6 About-us">
              <p className="footer-heads">About Us</p>
              <Link to={'/Aboutus'} style={{textDecoration:'none'}}>
              <p className="footer-links">
                About Us
              </p>
              </Link>
              <Link to={'/MyStores'} style={{textDecoration:'none '}}>
              <p className="footer-links">
                Stores
              </p>
              </Link>
             
              <Link to={'/Foodies'} style={{textDecoration:'none '}}>

              <p className="footer-links" onClick="/">
                Foodies Corner
              </p>
              </Link>

              <Link to={'/MyFranchise'} style={{textDecoration:'none '}}>
              <p className="footer-links" onClick="/">
                Franchise
              </p>
              </Link>
             
            
            </div>
            <div className="col-lg-3 col-md-6 About-us">
              <p className="footer-heads">Company</p>
              <p className="footer-links" onClick="/">
                Partnership
              </p>
              <p className="footer-links" onClick="/">
                Terms of Use
              </p>
              <p className="footer-links" onClick="/">
                Privacy
              </p>
              <p className="footer-links" onClick="/">
                Mercy Foods
              </p>
            </div>
            <div className="col-lg-3 col-md-6 About-us">
              <p className="footer-heads">Get in touch</p>
              <div className="footer-links">
                <p onClick={()=> window.open('tel:+919962184455')}><BsFillTelephoneFill style={{background:'#1cc8c8', padding:'6px', color:'#ffff', borderRadius:'100px', fontSize:'30px', marginRight:'5px'}}/>+91 99621 84455 </p>
                <p onClick={()=> window.open('mailto:franchise@hapningz.com')}><IoMdMail style={{background:'#1cc8c8', padding:'6px', color:'#ffff', borderRadius:'100px', fontSize:'30px', marginRight:'5px'}} />franchise@hapningz.com </p>
              </div>
             
              <div className="d-flex align-items-center justify-content-center">
                <input
                  className="footer-search"
                  type="email"
                  placeholder="Email"
                  spellCheck="false"
                />
                <button className="footer-mail">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <div className="bottom-text">Copyright © 2022 Hapningz.</div>
        <div className="bottom-icons-sec">
          <a href={'https://www.facebook.com/hapningz'}>
          <ImFacebook className="bottom-icons" />
          </a>

          <a href="">

          <BsTwitter className="bottom-icons" />
          </a>
          <BsInstagram className="bottom-icons" />
          <BsYoutube className="bottom-icons" />
          <BsPinterest className="bottom-icons" />
        </div>
      </div>
    </>
  );
};

export default Footer;
