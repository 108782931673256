import React, { useState } from 'react';
import { Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const Drawermenu = () => {
    const [openDrawer, setopenDrawer] = useState(false);
    return (
        <React.Fragment>
            <Drawer open={openDrawer}
                onClose={() => setopenDrawer(false)}     >
                <List>
                    <ListItemButton onClick={() => setopenDrawer(false)}>
                        <ListItemIcon>
                            <Link to="/" style={{ textDecoration: "none", color: 'black', fontWeight: 'bolder' }}>
                                <ListItemText >Home</ListItemText>
                            </Link>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <Link to="/Aboutus" style={{ textDecoration: "none", color: 'black', fontWeight: 'bolder' }}>
                                <ListItemText >About us</ListItemText>
                            </Link>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <Link to="/MyStores" style={{ textDecoration: "none", color: 'black', fontWeight: 'bolder' }}>
                                <ListItemText >Stores</ListItemText>
                            </Link>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <Link to="/MyMenu" style={{ textDecoration: "none", color: 'black', fontWeight: 'bolder' }}>
                                <ListItemText >Menu</ListItemText>
                            </Link>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <Link to="/MyFranchise" style={{ textDecoration: "none", color: 'black', fontWeight: 'bolder' }}>
                                <ListItemText >Franchise</ListItemText>
                            </Link>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <Link to="/Foodies" style={{ textDecoration: "none", color: 'black', fontWeight: 'bolder' }}>
                                <ListItemText >Foodies Corner</ListItemText>
                            </Link>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <Link to="/" style={{ textDecoration: "none", color: 'black', fontWeight: 'bolder' }}>
                                <ListItemText >Mercy Foods</ListItemText>
                            </Link>
                        </ListItemIcon>
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <Link to="/Contact" style={{ textDecoration: "none", color: 'black', fontWeight: 'bolder' }}>
                                <ListItemText >Contact us</ListItemText>
                            </Link>
                        </ListItemIcon>
                    </ListItemButton>

                </List>
            </Drawer>
            <IconButton sx={{ color: "black", marginLeft: 'auto' }} onClick={() => setopenDrawer(!openDrawer)}>
                <MenuIcon />
            </IconButton>
        </React.Fragment>
    )
}

export default Drawermenu;