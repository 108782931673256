import React from "react";
import "./Foodies.css";
// import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Navs from "../Navs/Navs";
import { Helmet } from "react-helmet";

const Foodies = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content="Hapningz - Foodies Corner" />
                <meta name="description"
                    content="The Most Happening place for Food Bloggers" />
                <title>Hapningz - Foodies Corner</title>
                <link rel="canonical" href="https://hapningz.com/Foodies" />
            </Helmet>
            <Navs />
            <div className="Foodies">
                <div className="container">
                    <div className="Foodies-sec">
                        <h3>Foodies Corner</h3>
                        <p>The Most Happening place for Food Bloggers</p>
                        <div className="Foodies-sec-btn">
                            <button className="Foodies-btn-1" onClick={"/"}>Blog</button>
                            <button className="Foodies-btn-2" onClick={"/"}>Block</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Foodies;
