import React, { useState } from "react";
import {
  AppBar,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Logo1 from "../../images/Logo1.png";
import Drawermenu from "./Drawermenu";
import { useNavigate } from "react-router-dom";

const Navs = () => {
  const [value, setValue] = useState();
  const theme = useTheme();
  console.log(theme);
  const isWatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isWatch);
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <AppBar sx={{ background: "#fff", boxShadow: 'none', borderBottom: '2px solid #CCCCCC', borderStyle: 'dashed' }}>
        <Toolbar style={{ justifyContent: 'space-around' }} >
          <Typography >
           
              <img src={Logo1} className="Navs-logo" alt="head logo" onClick={() => navigate("/")} style={{cursor:'pointer'}}/>
      
          </Typography>
          {isWatch ? (
            <>
              <Drawermenu />
            </>
          ) : (
            <>
              <Tabs
                sx={{ marginLeft: "0" }}
                value={value}
                onChange={(e, value) => setValue(value)}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label={<span style={{ fontWeight: 'bolder', color: 'black' }}> Home </span>} onClick={() => navigate("/")} />
                <Tab
                  label={<span style={{ fontWeight: 'bolder', color: 'black' }}> About us </span>}
                  onClick={() => navigate("/Aboutus")}
                />{" "}
                <Tab label={<span style={{ fontWeight: 'bolder', color: 'black' }}> Stores </span>} onClick={() => navigate("/MyStores")} />{" "}
                <Tab label={<span style={{ fontWeight: 'bolder', color: 'black' }}> Menu </span>} onClick={() => navigate("/MyMenu")} />{" "}
                <Tab
                  label={<span style={{ fontWeight: 'bolder', color: 'black' }}> Franchise </span>}
                  onClick={() => navigate("/MyFranchise")}
                />{" "}
                <Tab
                  label={<span style={{ fontWeight: 'bolder', color: 'black' }}> Foodies Corner </span>}
                  onClick={() => navigate("/Foodies")}
                />{" "}
                <Tab label={<span style={{ fontWeight: 'bolder', color: 'black' }}> Mercy Foods </span>} onClick={() => navigate("/")} />{" "}
                <Tab label={<span style={{ fontWeight: 'bolder', color: 'black' }}> Contact us </span>} onClick={() => navigate("/Contact")} />
              </Tabs>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Navs;
