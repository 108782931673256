import React, { useEffect, useRef } from "react";
import "./Restaurant.css";
import { IoIosArrowForward } from "react-icons/io";
import r1 from "../../images/r1.png";
import r2 from "../../images/r2.png";
import b1 from "../../images/b1.png";
import r3 from "../../images/r3.png";
import video from "../../Assets/hapningz.mp4";
import Restsmall from "../../images/Restsmall.png";
import { Link } from "react-router-dom";

const Restaurant = () => {

    const vidRef = useRef();

    useEffect(() => { vidRef.current.play(); }, []);
    return (
        <div className="Restaurant-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <img src={b1} className="Rest-blue-1" alt="vector-2" />
                        <h2 className="Rest-header">
                            Our <span style={{ color: "#e32227" }}>Restaurant</span>
                        </h2>
                        <div className="Rest-dash"></div>
                        <img src={r1} className="Rest-box-1" alt="vector-1" />
                        <p className="Rest-desc">See Our Latest Happy Foods</p>
                        <p className="Rest-desc">Our New Items and Dishes</p>
                        <p className="Rest-desc">From Youtube</p>
                        <button className="Rest-btn">
                            Visit More <IoIosArrowForward />
                        </button>
                        <img src={r3} className="Rest-box-3" alt="vector-3" />
                        <img src={r1} className="Rest-box-4" alt="vector-4" />
                    </div>
                    <div className="col-lg-7">
                        <img src={r3} className="Rest-video-box-1" alt="boxes" />
                        <img src={r3} className="Rest-video-box-2" alt="boxes" />
                        <Link to="/">
                            {/* <img src={video} className="Rest-video" alt="Restaurant-2" />
                         */}
                            <video width="100%" controls loop autoPlay ref={ vidRef } >
                                <source src={video} type="video/mp4" />
                            </video>
                        </Link>
                        {/* <img src={Restsmall} className="Rest-small-video" alt="mobile-rest" /> */}
                        <img src={r2} className="Rest-video-box-3" alt="redbox" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Restaurant;
