import React from 'react';
import { Helmet } from 'react-helmet';
import Navs from '../Navs/Navs';
import './MyStores.css';
import hotel4 from "../../images/abids2.jpeg";
import Slider from 'react-slick';
import Menu1 from '../../images/our menu/ch1.png'
import Menu2 from '../../images/our menu/se1.png';
import Menu3 from '../../images/our menu/p1.png';
import Menu4 from '../../images/our menu/b1.png';
import Menu5 from '../../images/our menu/s1.png';
import Menu6 from '../../images/our menu/pn1.png';
import Menu7 from '../../images/our menu/qb1.png';
import Menu8 from '../../images/our menu/d1.png';
import Menu9 from '../../images/our menu/be1.png';
import { baseUrls } from "./Configer";
import YouTube from "react-youtube";
import Zomatologo from "../../images/zomato.png";
import Swiggylogo from '../../images/swiggy.png';
import Grilledwings from '../../images/video/grilledwings.mp4';
import { Button } from 'react-bootstrap';
import { IoCall } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import Footer from '../Footer/Footer';




const Abidshyderabad = () => {

  const settings = {
    customPaging: function (i) {
      const baseUrl = baseUrls[i % baseUrls.length].url;
      return (
        <a>
          <img src={`${baseUrls}/abstract0${i + 1}.jpg`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      listType: "playlist",
      list: "PLzXR4AdxkG862npTDPnks9STK1rROZs7U", // Replace with your playlist ID
      autoplay: 1,
    },
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="title" content="Hapningz - Bast Fast Food Restaurants in Abids" />
        <meta name="description"
          content="  Whether you're looking for a quick lunch break or a casual dinner
          with friends and family, we have the perfect outlet for you. Our
          outlets are equipped with state-of-the-art kitchen equipment and
          modern cooking tools, our menu is diverse, and our staffs are
          dedicated to providing excellent service, with a focus on quality
          and customer satisfaction. With outlets strategically placed at
          prime locations, we ensure that our customers enjoy our delicious
          food and exceptional service. Our outlets have their unique
          ambiance and atmosphere, and are designed to provide a comfortable
          and convenient dining experience. Best Fast Food Restaurants in Kodambakkam" />
        <title>Hapningz - Bast Fast Food Restaurants in Abids</title>
        <link rel="canonical" href="https://hapningz.com/MyStores/Abids" />
      </Helmet>

      <div>
        <Navs />

        <div className='headbg'>
          <div className='container'>
            <h1 >Restaurants in Abids</h1>
          </div>

        </div>
        <div className='container mt-5'>
          <div className={"row"}>
            <div className={'col-lg-9'}>

              <div className={"Ab_images"}>
                <img src={hotel4} />
                <p>
                  As a critical member of our team, the individual in this role will play an integral part in ensuring the consistency and excellence of our food offerings across multiple franchise locations. This position involves collaboration with franchise owners, culinary teams, and suppliers to maintain the highest standards of food quality, safety, and compliance with health regulations. The ideal candidate will bring both a passion for food and a strong understanding of restaurant operations, allowing them to implement and uphold best practices across our franchises.
                  <br /><br />
                  As a voice of our franchise, you will also engage with customers and franchisees to gather feedback, identify trends, and proactively address any concerns regarding food quality or service.
                </p>
                {/* <Slider {...settings}>
                  <div>
                    <img src={baseUrls + "/abstract02.jpg"} />
                  </div>
                  <div>
                  <img src={baseUrls + "/abstract02.jpg"} />
                  </div>
                  <div>
                  <img src={baseUrls + "/abstract03.jpg"} />
                  </div>
                  <div>
                  <img src={baseUrls + "/abstract04.jpg"} />
                  </div>
                </Slider> */}
              </div>


              <div className="menu_img mt-5 mb-5">
                <h2>Our Menu</h2>
                <div className={"row"}>
                  <div className={"menu_1 col-lg-2 col-md-3 col-4 "}>
                    <img src={Menu1} width={'100%'} />

                  </div>
                  <div className={"menu_1 col-lg-2 col-md-3 col-4 "}>
                    <img src={Menu2} width={'100%'} />

                  </div>
                  <div className={"menu_1 col-lg-2 col-md-3 col-4 "}>
                    <img src={Menu3} width={'100%'} />

                  </div>
                  <div className={"menu_1 col-lg-2 col-md-3 col-4 "}>
                    <img src={Menu4} width={'100%'} />

                  </div>
                  <div className={"menu_1 col-lg-2 mt-3 col-md-3 col-4 "}>
                    <img src={Menu5} width={'100%'} />

                  </div>
                  <div className={"menu_1 col-lg-2 mt-3 col-md-3 col-4 "}>
                    <img src={Menu6} width={'100%'} />

                  </div>
                  <div className={"menu_1 col-lg-2 mt-3 col-md-3 col-4 "}>
                    <img src={Menu7} width={'100%'} />

                  </div>
                  <div className={"menu_1 col-lg-2 mt-3 col-md-3 col-4 "}>
                    <img src={Menu8} width={'100%'} />

                  </div>
                  <div className={"menu_1 col-lg-2 mt-3 col-md-3 col-4 "}>
                    <img src={Menu9} width={'100%'} />

                  </div>
                </div>


                <div className={" shorts_yt row mt-5"}>
                  <div className={" col-lg-3 "}>


                    <iframe width="100%" height="400"
                      src="https://www.youtube.com/embed/6tX7WWwAivQ"
                      title="#hapningz : Where Happiness Happenz! 🍽️ Craving a delicious feast?"
                      frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                  <div className={" col-lg-3"}>

                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/KqxsWoG2opA" title="#delicious  Oven Griled Wings at #hapningz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                  <div className={" col-lg-3"}>

                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/nFQ26Wcauqs" title="Who can resist this #delicious #brownie sizzling masterpiece?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>

                  <div className={" col-lg-3"}>
                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/bfOb6JuJ4uc" title="✨ Cheese-Filled Pasta Perfection! ✨" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>

                  <div className={" col-lg-3"}>

                  </div>

                </div>

              </div>
            </div>
            <div className={'col-lg-3'}>
              <div className={" zo_head"}>
                <h4>We are Also Available in</h4>
                <a href="https://zomato.onelink.me/xqzv/s0z95nut">

                  <img src={Zomatologo} width={'100%'} />
                </a>

                <a href='https://www.swiggy.com/menu/939701?source=sharing'>

                  <img src={Swiggylogo} width={'100%'} />
                </a>

                <div className='call_to_action'>
                  <h4>For More Details</h4>
                  <Button onClick={() => window.open('tel:+918124454455')} className={"button1"}><IoCall className={"icons"} />Call</Button>
                  <Button onClick={() => window.open('https://wa.me/+918124454455')} className={"button2"} ><IoLogoWhatsapp className={"icons"} />What'sApp</Button>
                </div>

                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15229.955235295665!2d78.47282!3d17.3883142!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97932745ada5%3A0x5d2d9326fa7c216b!2sHapningz%20-%20Abids!5e0!3m2!1sen!2sin!4v1729850218509!5m2!1sen!2sin"
                  width="100%" height="600"
                  allowfullscreen="true"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>

              </div>

            </div>
          </div>
        </div>
      </div>

      <Footer />



    </>
  )
}

export default Abidshyderabad