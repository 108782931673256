import React from "react";
import "./Aboutus.css";
// import Navbar from "../Navbar/Navbar";
import Navs from "../Navs/Navs";
import Vision from "../../images/Vision.jpg";
import Mission from "../../images/Mission.jpg";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";

const Aboutus = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="title" content="Hapningz - About Us" />
        <meta name="description"
          content="Our Vision is to revolutionize the quick service restaurant
          industry and go beyond providing palatable delicacies to create
          an enjoyable and most happening dining experience for our
          customers and simultaneously create, nurture and empower
          entrepreneurship through franchising concepts." />
        <title>Hapningz - About Us</title>
        <link rel="canonical" href="https://hapningz.com/Aboutus" />
      </Helmet>
      <Navs />
      <div className="Aboutus">
        <div className="container">
          <div className="Aboutus-one">
            <div className="row">
              <div className="col-lg-6">
                <div className="Aboutus-Vision">
                  <p className="Aboutus-Vision-header">Vision</p>
                  <p className="Aboutus-Vision-desc">
                    Our Vision is to revolutionize the quick service restaurant
                    industry and go beyond providing palatable delicacies to create
                    an enjoyable and most happening dining experience for our
                    customers and simultaneously create, nurture and empower
                    entrepreneurship through franchising concepts.{" "}
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={Vision}
                  className="Aboutus-Vision-img"
                  alt="Blue Vison"
                />
              </div>
            </div>
          </div>
          <div className="Aboutus-second">
            <div className="row">
              <div className="col-lg-6">
                <img
                  src={Mission}
                  className="Aboutus-Mission-img"
                  alt="Blue Vison"
                />
              </div>
              <div className="col-lg-6">
                <div className="Aboutus-Mission">
                  <p className="Aboutus-Mission-header">Mission</p>
                  <p className="Aboutus-Mission-desc">
                    As One of the pioneers of quick service restaurant chain in India, we make it our mission to spread the joy of dining.
                    <p className="Aboutus-para">Provide Delicious, high-quality meals that are always made fresh and served with exceptional customer service.</p>
                    {" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Aboutus;
