import React from "react";
import "./MyMenu.css";
// import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Navs from "../Navs/Navs";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import 'react-tabs/style/react-tabs.css';
import burger from "../../images/burger.png";
import pizza from "../../images/pizza.png";
import cupcake from "../../images/cupcake.png";
import ramen from "../../images/Ramen.png";
import ice from "../../images/ice.png";
import hotel1 from "../../images/hotel1.jpg";
import Display from "../../images/menu-dp.png";
import Star from "../../images/menu-star.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Helmet } from "react-helmet";
import Accordion from 'react-bootstrap/Accordion';
import Ourmenu from '../../images/menu/Our Menu.jpg';
import Beverages from '../../images/menu/Beverages.jpg';
import Sandwiches from '../../images/menu/Sandwiches.jpg';
import DessertsIceCreams from '../../images/menu/Desserts & Ice Creams.jpg';
import FastFood from '../../images/menu/Fast Food Delicacies.jpg';
import ChickenSeafood from '../../images/menu/Chicken & Seafood.jpg';



const menuImages = [{
    id: 1,
    price: "200",
    category: "Description",
    linkImg: hotel1,
}]

const MyMenu = () => {
    const Menusettings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        rtl: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]

    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="title" content="Hapningz - Our Menu" />
                <meta name="description"
                    content="We are serving you with: Burger, Pizza, Sandwich, Wrap, Noodles, Pasta, Chicken Delicacies, Sea Food Delicacies, Fried Starters & Sides, Desserts & Ice Creams, Beverages, Signature Dishes." />
                <title>Hapningz - Our Menu</title>
                <link rel="canonical" href="https://hapningz.com/MyMenu" />
            </Helmet>
            <Navs />
            <div className="MyMenu">
                <div className="container">
                    <Accordion defaultActiveKey="0" className="MyMenu-sec">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <h3 style={{fontWeight:'bold', fontSize:'2rem'}}>Our Menu</h3>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className={"row"}>
                                    <div className={'col-lg-4'}>
                                        <img src={Ourmenu} width={'100%'} alt="Our Menu">
                                        </img>
                                    </div>
                                    <div className={'col-lg-7'}>
                                        <p>We are serving you with:
                                            Burger, Pizza, Sandwich, Wrap, Noodles, Pasta, Chicken Delicacies, Sea Food Delicacies, Fried Starters & Sides, Desserts & Ice Creams, Beverages, Signature Dishes.
                                        </p>
                                        <p>At Hapningz, we pride ourselves on offering exceptional quick service food that caters to all tastes. Our skilled chefs put their hearts into crafting delicious dishes that are sure to please. We specialize in a unique blend of Indian and Western fast food, with signature dishes that are inspired by Indian food culture. Whether you're looking for a quick bite on the go or a place to relax and rejuvenate, we have something for everyone. Our snacks, sweet treats, and sandwiches are designed to perfectly complement our wide selection of drinks, making Hapningz the ideal spot for any occasion.</p>

                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <h4 style={{fontWeight:'bold', fontSize:'2rem'}}>Beverages</h4>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className={"row"}>
                                    <div className={'col-lg-4'}>
                                        <img src={Beverages} width={'100%'} alt="Beverages">
                                        </img>
                                    </div>
                                    <div className={'col-lg-7'}>
                                        <p>We understand that a delicious meal is not complete without a refreshing beverage to wash it down. That's why we offer a wide variety of beverages to complement your meal. From classic sodas and iced teas to premium coffee and smoothies, we have something for every taste. Our beverages are made with high-quality ingredients and are sure to quench your thirst. Whether you're looking for something sweet or something to energize you, our selection of beverages will leave you satisfied. We also offer a variety of specialty drinks like cold-pressed juices, milkshakes, and frappes that are perfect for any occasion. Come in today and try one of our delicious beverages!</p>

                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <h4 style={{fontWeight:'bold', fontSize:'2rem'}}>Sandwiches</h4>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className={"row"}>
                                    <div className={'col-lg-4'}>
                                        <img src={Sandwiches} width={'100%'} alt="Sandwiches">
                                        </img>
                                    </div>
                                    <div className={'col-lg-7'}>
                                        <p>We specialize in freshly made sandwiches that are sure to satisfy any craving. Our menu features a variety of options, including classic favorites like veg and cheese, as well as unique and creative combinations. All of our sandwiches are made to order using only the freshest ingredients, including premium chicken, cheeses, and veggies. Whether you're in the mood for a hearty breakfast sandwich or a classic BLT, we have something for everyone. And, our sandwiches are perfect for lunch, dinner, or a quick snack. Come and try our sandwiches today, we are sure you will love them!</p>

                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>
                                <h4 style={{fontWeight:'bold', fontSize:'2rem'}} >Desserts & Ice Creams </h4>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className={"row"}>
                                    <div className={'col-lg-4'}>
                                        <img src={DessertsIceCreams} width={'100%'} alt="Desserts & Ice Creams">
                                        </img>
                                    </div>
                                    <div className={'col-lg-7'}>
                                        <p>We understand the importance of satisfying your sweet tooth. That's why we offer a wide selection of delectable desserts and ice creams to finish off your meal. From classic favorites like cookies and brownies to more indulgent options like apple pie and chocolate lava cake, we have something to suit every taste. Our ice cream flavors include creamy vanilla, rich chocolate, and seasonal favorites like pumpkin spice and peppermint bark. We also offer freshly baked pastries and sweet treats other than sundaes, milkshakes and smoothies to cool you down. Whether you're looking for a sweet treat to cap off a meal or a refreshing dessert to beat the heat, we've got you covered.</p>
                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>
                                <h4 style={{fontWeight:'bold', fontSize:'2rem'}}>Fast Food Delicacies </h4>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className={"row"}>
                                    <div className={'col-lg-4'}>
                                        <img src={FastFood} width={'100%'} alt="Fast Food Delicacies">
                                        </img>
                                    </div>
                                    <div className={'col-lg-7'}>
                                        <p>Hapningz has something to please everyone at any time of the day. We offer a wide variety of delicious options for all your cravings. Our menu includes classic burgers, made with fresh, juicy meat, chicken, or veg patty, topped with all your favourite toppings. We also serve piping hot pizzas, with a variety of toppings to choose from. If you're in the mood for something different, try one of our wraps, loaded with savoury fillings. We also offer a selection of noodles and pasta dishes, made with the freshest ingredients, and cooked to perfection. Whether you're in the mood for a hearty burger or a light and healthy wrap, we have something to satisfy every taste.</p>

                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>
                                <h4 style={{fontWeight:'bold', fontSize:'2rem'}}>Chicken & Seafood Delicacies</h4>

                            </Accordion.Header>
                            <Accordion.Body>
                                <div className={"row"}>
                                    <div className={'col-lg-4'}>
                                        <img src={ChickenSeafood} width={'100%'}  alt="Chicken & Seafood Delicacies">
                                        </img>
                                    </div>
                                    <div className={'col-lg-7'}>
                                        <p>We're excited to offer a wide variety of delicious chicken and seafood delicacies to satisfy all palates. Our menu features succulent chicken dishes, from classic fried chicken to bold and flavourful curries. For seafood lovers, we've got you covered with an array of mouth-watering options like crispy fried shrimp, savoury crab cakes and delectable fish and chips. All of our dishes are made with the freshest ingredients and prepared to perfection, ensuring a satisfying dining experience every time. Whether you're in the mood for a quick lunch or a hearty dinner, our menu has something for everyone. So come on in and treat yourself to some delicious chicken and seafood delicacies.</p>


                                    </div>

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="MyMenu-sec">







                    </div>
                    <div className="board-bg">
                        <div className="Menu-header">
                            <p>
                                <span style={{ color: "#e32227" }}>Menu </span> that
                                <span style={{ color: "#2cbbbf" }}> Always </span> Make
                            </p>
                            <p>
                                {" "}
                                You Fall In
                                <span style={{ color: "#e32227" }}> Love</span>
                            </p>

                        </div>

                        {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={3}>
                                    <Nav variant="pills" className="Menu-Food-list" >
                                        <Nav.Item >
                                            <Nav.Link eventKey="first">

                                                {" "}
                                                <img
                                                    src={burger}
                                                    className="Menu-icons"
                                                    alt="burger-pic"
                                                />
                                                <span className="Menu-food-name">Burger</span>


                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item >
                                            <Nav.Link eventKey="second">
                                                <img
                                                    src={pizza}
                                                    className="Menu-icons"
                                                    alt="pizza-pic"
                                                />
                                                <span className="Menu-food-name">
                                                    Pizza
                                                </span>

                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">
                                                <img
                                                    src={cupcake}
                                                    className="Menu-icons"
                                                    alt="cupcake-pic"
                                                />
                                                <span className="Menu-food-name">
                                                    Cup Cake
                                                </span>

                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fourth">
                                                <img
                                                    src={ramen}
                                                    className="Menu-icons"
                                                    alt="ramen-pic"
                                                />
                                                <span className="Menu-food-name">
                                                    Ramen
                                                </span>

                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fifth">
                                                <img src={ice} className="Menu-icons" alt="ice pic" />
                                                <span className="Menu-food-name">
                                                    Ice Cream
                                                </span>

                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </
                                Col>

                                <Col sm={9}>
                                    <Tab.Content className="mx-5">
                                        <Tab.Pane eventKey="first">
                                            <Slider {...Menusettings}>
                                                <div className="Menu-food-card">
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 1"
                                                    />
                                                    <div className="Menu_details">
                                                        <div className="Menu-price">100₹</div>
                                                        <FavoriteBorderIcon className="Menu-heart" />
                                                        <div>
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-1"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-2"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-3"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Star}
                                                                className="Menu-star"
                                                                alt="Menu-Star"
                                                            />
                                                            <h5 className="Menu-rating">(4.5)</h5>
                                                        </div>
                                                        <div className="Menu-description">
                                                            <h4 className="Menu-description-head">
                                                                Burger XXL
                                                            </h4>
                                                            <p className="Menu-description-para">
                                                                Lorem ipsum dolor, sit amet consectetur
                                                                adipisicing elit. Reiciendis, quae!
                                                            </p>
                                                        </div>
                                                        <button onClick="/" className="Menu-buttons">
                                                            Order Now
                                                        </button>
                                                    </div>
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 2"
                                                    />
                                                    <div className="Menu_details">
                                                        <div className="Menu-price">100₹</div>
                                                        <FavoriteBorderIcon className="Menu-heart" />
                                                        <div>
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-1"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-2"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-3"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Star}
                                                                className="Menu-star"
                                                                alt="Menu-Star"
                                                            />
                                                            <h5 className="Menu-rating">(4.5)</h5>
                                                        </div>
                                                        <div className="Menu-description">
                                                            <h4 className="Menu-description-head">
                                                                Burger XXL
                                                            </h4>
                                                            <p className="Menu-description-para">
                                                                Lorem ipsum dolor, sit amet consectetur
                                                                adipisicing elit. Reiciendis, quae!
                                                            </p>
                                                        </div>
                                                        <button onClick="/" className="Menu-buttons">
                                                            Order Now
                                                        </button>
                                                    </div>
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 3"
                                                    />
                                                    <div className="Menu_details">
                                                        <div className="Menu-price">100₹</div>
                                                        <FavoriteBorderIcon className="Menu-heart" />
                                                        <div>
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-1"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-2"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-3"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Star}
                                                                className="Menu-star"
                                                                alt="Menu-Star"
                                                            />
                                                            <h5 className="Menu-rating">(4.5)</h5>
                                                        </div>
                                                        <div className="Menu-description">
                                                            <h4 className="Menu-description-head">
                                                                Burger XXL
                                                            </h4>
                                                            <p className="Menu-description-para">
                                                                Lorem ipsum dolor, sit amet consectetur
                                                                adipisicing elit. Reiciendis, quae!
                                                            </p>
                                                        </div>
                                                        <button onClick="/" className="Menu-buttons">
                                                            Order Now
                                                        </button>
                                                    </div>
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 4"
                                                    />
                                                    <div className="Menu_details">
                                                        <div className="Menu-price">100₹</div>
                                                        <FavoriteBorderIcon className="Menu-heart" />
                                                        <div>
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-1"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-2"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-3"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Star}
                                                                className="Menu-star"
                                                                alt="Menu-Star"
                                                            />
                                                            <h5 className="Menu-rating">(4.5)</h5>
                                                        </div>
                                                        <div className="Menu-description">
                                                            <h4 className="Menu-description-head">
                                                                Burger XXL
                                                            </h4>
                                                            <p className="Menu-description-para">
                                                                Lorem ipsum dolor, sit amet consectetur
                                                                adipisicing elit. Reiciendis, quae!
                                                            </p>
                                                        </div>
                                                        <button onClick="/" className="Menu-buttons">
                                                            Order Now
                                                        </button>
                                                    </div>
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 5"
                                                    />
                                                    <div className="Menu_details">
                                                        <div className="Menu-price">100₹</div>
                                                        <FavoriteBorderIcon className="Menu-heart" />
                                                        <div>
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-1"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-2"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Display}
                                                                className="Menu-display-3"
                                                                alt="Menu-Dp"
                                                            />
                                                            <img
                                                                src={Star}
                                                                className="Menu-star"
                                                                alt="Menu-Star"
                                                            />
                                                            <h5 className="Menu-rating">(4.5)</h5>
                                                        </div>
                                                        <div className="Menu-description">
                                                            <h4 className="Menu-description-head">
                                                                Burger XXL
                                                            </h4>
                                                            <p className="Menu-description-para">
                                                                Lorem ipsum dolor, sit amet consectetur
                                                                adipisicing elit. Reiciendis, quae!
                                                            </p>
                                                        </div>
                                                        <button onClick="/" className="Menu-buttons">
                                                            Order Now
                                                        </button>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Slider {...Menusettings}>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 6"
                                                    />
                                                    <MenuCard />
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 7"
                                                    />
                                                    <MenuCard />
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 8"
                                                    />
                                                    <MenuCard />
                                                </div>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 9"
                                                    />
                                                    <MenuCard />
                                                </div>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 10"
                                                    />
                                                    <MenuCard />
                                                </div>
                                            </Slider>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <Slider {...Menusettings}>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 11"
                                                    />
                                                    <MenuCard />
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 12"
                                                    />
                                                    <MenuCard />
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 13"
                                                    />
                                                    <MenuCard />
                                                </div>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 14"
                                                    />
                                                    <MenuCard />
                                                </div>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 15"
                                                    />
                                                    <MenuCard />
                                                </div>
                                            </Slider>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fourth">
                                            <Slider {...Menusettings}>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 16"
                                                    />
                                                    <MenuCard />
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 17"
                                                    />
                                                    <MenuCard />
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 18"
                                                    />
                                                    <MenuCard />
                                                </div>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 19"
                                                    />
                                                    <MenuCard />
                                                </div>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 20"
                                                    />
                                                    <MenuCard />
                                                </div>
                                            </Slider>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fifth">
                                            <Slider {...Menusettings}>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 21"
                                                    />
                                                    <MenuCard />
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 22"
                                                    />
                                                    <MenuCard />
                                                </div>

                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 23"
                                                    />
                                                    <MenuCard />
                                                </div>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 24"
                                                    />
                                                    <MenuCard />
                                                </div>
                                                <div>
                                                    <img
                                                        src={hotel1}
                                                        className="Menu_image"
                                                        alt="Menu card 25"
                                                    />
                                                    <MenuCard />
                                                </div>
                                            </Slider>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container> */}
                    </div>




                </div>
            </div>
            <Footer />
        </>
    );
};

const MenuCard = ({ item }) => {
    return (
        <div className="Menu_details">
            <div className="Menu-price">100₹</div>
            <FavoriteBorderIcon className="Menu-heart" />
            <div>
                <img
                    src={Display}
                    className="Menu-display-1"
                    alt="Menu-Dp"
                />
                <img
                    src={Display}
                    className="Menu-display-2"
                    alt="Menu-Dp"
                />
                <img
                    src={Display}
                    className="Menu-display-3"
                    alt="Menu-Dp"
                />
                <img
                    src={Star}
                    className="Menu-star"
                    alt="Menu-Star"
                />
                <h5 className="Menu-rating">(4.5)</h5>
            </div>
            <div className="Menu-description">
                <h4 className="Menu-description-head">
                    Burger XXL
                </h4>
                <p className="Menu-description-para">
                    Lorem ipsum dolor, sit amet consectetur
                    adipisicing elit. Reiciendis, quae!
                </p>
            </div>
            <button onClick="/" className="Menu-buttons">
                Order Now
            </button>
        </div>

    );
};


export default MyMenu;
